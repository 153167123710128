import {setStorage} from "@/utility/helper";

export const setLogin = (auth_token: string, email: string) => {
  setStorage('auth_token', auth_token)
  setStorage('email', email)
  document.getElementById('my-account-menu')?.classList.remove('hidden');
  document.getElementById('sign-up-menu')?.classList.add('hidden');
  document.getElementById('sign-in-menu')?.classList.add('hidden');
  document.getElementById('my-account-menu-responsive')?.classList.remove('hidden');
  document.getElementById('sign-up-menu-responsive')?.classList.add('hidden');
  document.getElementById('sign-in-menu-responsive')?.classList.add('hidden');
  const googleOnTapPrompt = document.getElementById('google-prompt');
  if (googleOnTapPrompt) {
    googleOnTapPrompt.classList.add('hidden');
  }
}


